import { render, staticRenderFns } from "./FleetOverview.vue?vue&type=template&id=71c9643a&scoped=true&"
import script from "./FleetOverview.vue?vue&type=script&lang=ts&"
export * from "./FleetOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c9643a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VContainer,VTextField})

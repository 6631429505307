









import { Component, Prop, Vue } from "vue-property-decorator";
//  components
import DataIndicator from "@/components/dataQuality/FleetOverviewTable/DataIndicator.vue";
//  types
import { CellData, IndicatorData } from "@/types/dataQuality/dataQualityTable";

@Component({
  components: {
    DataIndicator,
  },
})
export default class DataCell extends Vue {
  @Prop({ type: Object }) data!: CellData;

  //  @Getters
  get isIndicator(): boolean {
    if (!this.data) return false;
    return this.data.type === "dataIndicator";
  }

  get IndicatorData(): IndicatorData | undefined {
    if (!this.isIndicator) return;
    return {
      status: this.data.value,
      text: this.indicatorText,
      icon: this.indicatorIcon,
      link: null,
    };
  }

  get indicatorText(): string {
    if (this.data.column === "dataAvailability") {
      switch (this.data.value) {
        case 1:
          return "Available";
        case 2:
          return "Observe";
        case 3:
          return "Critical";
        default:
          return "N/A";
      }
    } else if (this.data.column === "dataOutliers") {
      switch (this.data.value) {
        case 1:
          return "No Outliers";
        case 2:
          return "Minor Outliers";
        case 3:
          return "Major Outliers";
        default:
          return "N/A";
      }
    } else if (this.data.column === "speedLog") {
      switch (this.data.value) {
        case 1:
          return "Good Quality";
        case 2:
          return "Degrading Quality";
        case 3:
          return "Poor Quality";
        default:
          return "N/A";
      }
    } else {
      return "N/A";
    }
  }

  get indicatorIcon(): string {
    if (this.data.column === "dataAvailability") {
      switch (this.data.value) {
        case 1:
          return "mdi-check-circle";
        case 2:
          return "mdi-alert-circle";
        case 3:
          return "mdi-minus-circle";
        default:
          return "mdi-close-circle";
      }
    } else if (this.data.column === "dataOutliers") {
      switch (this.data.value) {
        case 1:
          return "mdi-database-check";
        case 2:
          return "mdi-database-alert";
        case 3:
          return "mdi-database-alert";
        default:
          return "mdi-close-circle";
      }
    } else if (this.data.column === "speedLog") {
      switch (this.data.value) {
        case 1:
          return "mdi-check-circle";
        case 2:
          return "mdi-alert-circle";
        case 3:
          return "mdi-minus-circle";
        default:
          return "mdi-close-circle";
      }
    } else {
      return "mdi-close-circle";
    }
  }
}
